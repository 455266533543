import { IconButton } from '@mui/material';
import {Tooltip} from 'react-tooltip';

import React, {useState, useEffect} from 'react';
import './copyButton.scss'

const CopyButton = (props) => {

    const {link, setSnackbarOpen, id} = props;
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {

      setIsMounted(true);

      return () => setIsMounted(false)
    }, [])

    function unsecuredCopyToClipboard(text) {
        const el = document.createElement("input");
        el.value = text;
        document.body.append(el);
        el.select();
        el.setSelectionRange(0, text.length);
        if (document.execCommand("copy")) {
          setSnackbarOpen(true)
        }
        el.remove();
      }

    const copyToClipboard = () => {

        if(navigator.clipboard && window.isSecureContext){
            navigator.clipboard.writeText(link)
            .then(() => {
                setSnackbarOpen(true)
            })
            .catch(() => {
                console.log("Failed to copy the link")
            })
        }else{
            unsecuredCopyToClipboard(link)
        } 
    }

    return (
      <div className="copyButton-container">
        
        <IconButton
          className="copyButton__button"
          onClick={copyToClipboard}
          style={{ outline: "none" }}
          id={`mbox-copy-link-button__${id}`}
          // data-tooltip-id="my-tooltip"
          // data-tooltip-html={`<div style="max-width:250px">Copy Link <br /> ${link}</div>`}
          // data-tooltip-place="bottom"
          // data-tooltip-delay-show={300}
        >
          <i className="fad fa-copy" ></i>
        </IconButton>
        {isMounted && (
          <>
            <Tooltip
              anchorSelect={`#mbox-copy-link-button__${id}`}
              place="bottom"
              content={`${link}`}
              style={{ maxWidth: "200px", zIndex: 999999 }}
            />
            <Tooltip
              anchorSelect="[id^='mbox-copy-link-button__']"
              place="top"
              content="Copy link"
              style={{ maxWidth: "200px" }}
            />
          </>
        )}
      </div>
    );
}

export default CopyButton;
