import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import routes from "../../config/routes.json";
import { makeHttpCall } from "../../utils/HttpHelpers";

function ClearAndLogin(props) {
  const [error, setError] = useState(false);
  const { setIsLogin, onSetAppId } = props;
  const params = useParams();
  const history = useHistory();

  const url = `https://api-lb.virtual-meeting.net/v1/translationkey_mappings.json?include_translations=1&include_translatable=1&translation_language=mbox.landing.config&translatable_type=App&translatable_id=${params.id}&smart_find=0`;

  const getLoginCred = async () => {
    localStorage.setItem("app_id", params.id);
    if(onSetAppId) onSetAppId(params.id);
    const apiRes = await makeHttpCall({
      url: url,
      method: "GET",
      isVm: true,
    });

    if (apiRes?.ok) {
      const value = JSON.parse(
        apiRes.data[0].translationkey.translations[0].value
      );

      if (value?.loginCredentials) {
        history.push({
          pathname: routes.LOGIN,
          state: {
            appId: params.id,
            credentials: {
              ...value.loginCredentials,
            },
          },
        });
      } else {
        setError(true);
      }
    }
  };

  useEffect(() => {
    localStorage.removeItem("app_id");
    localStorage.removeItem("role");
    localStorage.removeItem("event_id");
    localStorage.removeItem("event_title");
    localStorage.removeItem("user_name");
    localStorage.removeItem("event_ends_at");
    localStorage.removeItem("event_starts_at");
    localStorage.removeItem("access_token");
    localStorage.removeItem("mboxSeletah.user");
    localStorage.removeItem("expires_at");
    setIsLogin(false);
    getLoginCred();
  }, []);

  if (error) {
    return (
      <div
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "20px",
        }}
      >
        Login credentials are missing, please use oca link for login
      </div>
    );
  }

  return <div>RedirectPage {params.id}</div>;
}

export default ClearAndLogin;
