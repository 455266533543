import React, {useContext} from "react";
import MBoxContext from "../../context/MBoxContext";
import { capitalizeFirstLetter } from '../../store/helper';
import './SessionEditModal.css';


function SessionEditModal(props) {

    const {setContentsessionValue, contentsessionValue, updateContentsession, isUpdating, ID} = props;
    const { contentSessionSettingsField} = useContext(MBoxContext)

    const handleChange = (e, item) => {
        const keyName = e.target.name;

        if(keyName === "contentsession_isBoolean"){
            setContentsessionValue({...contentsessionValue, isBoolean:{...contentsessionValue.isBoolean, [item]:e.target.checked}})
        }else{
            setContentsessionValue({...contentsessionValue, isText:{...contentsessionValue.isText, [item]:e.target.value}})
        }
    }

    const checkReadOnly = (title) => {
      const findSetting = contentSessionSettingsField.filter(item => !item.editable);

      const titleInReadOnly = findSetting?.filter(item => item.title === title);

      return titleInReadOnly;
    }

    const getWarnText = (title) => {
      const findSetting = contentSessionSettingsField.filter(item => item.title === title);
      return findSetting[0];
    }

  return (
    <div
      className="modal reasign-modal-container"
      tabIndex="-1"
      role="dialog"
      id={`sessionEditModal_${ID}`}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content reasign-modal__content">
          <div className="modal-header">
            <h5 className="modal-title mbox-headline-medium">
              Session Settings
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" style={{ color: "white" }}>
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body contentsession-modal__content__body">
            {Object.keys(contentsessionValue?.isBoolean).map((item, key) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "1rem 0",
                }}
              >
                <div className="form-check contentsession-modal__content__item mbox-title-medium">
                  <input
                    className="form-check-input"
                    name="contentsession_isBoolean"
                    type="checkbox"
                    id={`contentsession_isboolean_${item}`}
                    onChange={(e) => handleChange(e, item)}
                    checked={
                      contentsessionValue.isBoolean[item] === "true" ||
                      contentsessionValue.isBoolean[item] === true
                        ? true
                        : false
                    }
                    disabled={checkReadOnly(item).length !== 0}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`contentsession_isboolean_${item}`}
                  >
                    {capitalizeFirstLetter(item)}
                  </label>
                </div>
                {getWarnText(item)?.warnText && <span style={{color:"gray", marginTop:"5px"}}>{getWarnText(item)?.warnText}</span>}
              </div>
            ))}
            {Object.keys(contentsessionValue?.isText).map((item, key) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "1rem 0",
                }}
              >
                <div
                  className="form-check row contentsession-modal__content__item mbox-title-medium"
                >
                  <label
                    htmlFor={`contentsession_isText_${item}`}
                    className="mbox-title-medium"
                  >
                    {capitalizeFirstLetter(item)} :{" "}
                  </label>
                  <div className="ml-2">
                    <input
                      type="text"
                      className="form-control"
                      style={{ maxWidth: "300px" }}
                      value={contentsessionValue?.isText[item]}
                      id={`contentsession_isText_${item}`}
                      onChange={(e) => handleChange(e, item)}
                      disabled={checkReadOnly(item).length !== 0}
                    />
                  </div>
                </div>
                {getWarnText(item)?.warnText && <span style={{color:"gray", marginTop:"5px"}}>{getWarnText(item)?.warnText}</span>}
              </div>
            ))}
          </div>
          {/* <div className="modal-footer" style={{ borderTop: "none" }}>
            <button
              type="button"
              className="reasing-modal__close-button"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="mBoxListCard-link mbox-captions"
              style={{ borderRadius: "8px" }}
              onClick={updateContentsession}
              disabled={isUpdating}
            >
              {isUpdating ? (
                <>
                  {" "}
                  <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </>
              ) : (
                "Save Changes"
              )}
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default SessionEditModal;