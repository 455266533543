import React, { useState, useLayoutEffect, useEffect, useContext} from "react";
import _ from "lodash";
import "./selectContentsession.css";
import CardWithIcon from "../../components/Cards/CardWithIcon";
import useApiHook from "../../hooks/useApiHook";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Snackbar } from "@mui/material";
import { addParamsToQs, getTranslationOrTempId, get_translations } from "../../store/helper";
import MBoxContext from "../../context/MBoxContext";
import { get } from "../../utils/SetLocalstorage";
import { LocalstorageContentsessionConfig } from "../../config/LocalstorageConfig";
import Page from "../../components/common/Page";
import { deflate64 } from "../../utils/PakoHelper";
import { Base64 } from './../../utils/Base64';
import SectionContainer from './SectionContainer';
import SessionHeader from './SessionHeader';


const SelectContentsession = () => {
  const { 
    pageTitle, 
    baseUrl, 
    servicesList, 
    facultyUrl, 
    lobbyUrl, 
    streamingAdminUrl,
    qnaModeratorUrl,
    generalConfig,
    appText,
    showQrCode 
  } = useContext(MBoxContext);
  const [isOpen, setIsOpen] = useState(false);
  const [sessionConfig, setSessionConfig] = useState(servicesList);
  const [filteredAuthors, setFilteredAuthors] = useState([]);
  const [resourceInformation, setResourceInformation] = useState([]);
  const { CONTENTSESSION } = LocalstorageContentsessionConfig;
  let storageSession = get(CONTENTSESSION);
  let contentsession_id = storageSession?.id;
  

  const faculty = facultyUrl;

  const url = `https://${baseUrl}/v1/contentsessions/${contentsession_id}.json?&include_resourcegroups_body=1&use_cache=0`;

  const { data, isLoading } = useApiHook(url);

  const dateFormatShowTime = new Intl.DateTimeFormat("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  useEffect(() => {
    const existID = [];
    const authors = [];
    const resourcesInfo = [];

    if (data && data?.resourcegroups?.length > 0) {
      for (let i = 0; i < data?.resourcegroups?.length; i++) {
        for (
          let r = 0;
          r < data?.resourcegroups[i].body?.resources?.length;
          r++
        ) {
          if (data?.resourcegroups[i].body?.resources[r].type === "Content") {
            resourcesInfo.push(data?.resourcegroups[i].body?.resources[r]);
            for (
              let j = 0;
              j < data?.resourcegroups[i].body?.resources[r]?.authors?.length;
              j++
            ) {
              if (
                !existID.includes(
                  data?.resourcegroups[i].body?.resources[r].authors[j].id
                )
              ) {
                existID.push(
                  data?.resourcegroups[i].body?.resources[r].authors[j].id
                );
                authors.push(
                  data?.resourcegroups[i].body?.resources[r].authors[j]
                );
              }
            }
          }
        }
      }
      setFilteredAuthors(authors);
      setResourceInformation(resourcesInfo);
    }
  }, [data]);


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Page name="session-page">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Copied To Clipboard"
      />
      <div>
        <SessionHeader session={storageSession}/>
        {/* Resource information */}
        {resourceInformation?.length > 0 && (
          <SectionContainer title={pageTitle.selectContentSessionPage.resourcesInformation} showAdditionInfo={true}>
              {resourceInformation.map((r, index) => (
                <CardWithIcon
                  key={index}
                  title={r.title}
                  showQrCode={showQrCode}
                  id="mbox_resource_information_index"
                  icon="fas fa-file-powerpoint"
                  containerStyle={
                    r?.is_uploaded
                      ? { border: "2px solid green" }
                      : { border: "2px solid red" }
                  }
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    
                    <span>
                      Is Uploaded :{" "}
                      {r?.is_uploaded ? (
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "green" }}
                        ></i>
                      ) : (
                        <i
                          className="fas fa-times-circle"
                          style={{ color: "red" }}
                        ></i>
                      )}
                    </span>
                    {r?.main_author && (
                      <span>
                        Main Author :{" "}
                        {`${r?.main_author?.firstname} ${r?.main_author?.surname}`}
                      </span>
                    )}
                    {r?.starts_at_local?.length > 0 && <span>Start Time:  {`${dateFormatShowTime.format(new Date(r?.starts_at_local))}`}</span>}
                    {r?.ends_at_local?.length > 0 && <span>End Time:  {`${dateFormatShowTime.format(new Date(r?.ends_at_local))}`}</span>}
                  </div>
                </CardWithIcon>
              ))}
          </SectionContainer>
        )}

        {/*Speaker Devices */}
        <SectionContainer id="speaker-devices" title= {pageTitle.selectContentSessionPage.speakerDevices}>
            <CardWithIcon
              title={appText?.txtAppAutoControlTitle}
              id="MBox_mTalk_autocontrol_client"
              icon="fas fa-podium fa-fw"
              link={`${faculty}?sid=${contentsession_id}&SpeakerFullName=Lectern&autoConnect=1&follow_auto=1`}
              showCopyButton
              setSnackbarOpen={setIsOpen}
              description={appText?.txtAppAutoControlDesc}
              showQrCode={showQrCode}
            />
            <CardWithIcon
              title={appText?.txtAppLecternNameTitle}
              description={appText?.txtAppLecternNameDesc}
              id="MBox_Lectern_name_frontscreen"
              icon="fas fa-podium-star fa-fw"
              link={`${faculty}?sid=${contentsession_id}&bg_color=eaeaea&follow_auto=1&${getTranslationOrTempId(
                "MLectern",
                servicesList
              )}#/mlook`}
              showCopyButton
              setSnackbarOpen={setIsOpen}
              showQrCode={showQrCode}
            />
            <CardWithIcon
              title={appText?.txtAppSpeechTimerTitle}
              id="MBox_SpeechTimer"
              icon="fas fa-podium-star fa-fw"
              link={`${faculty}?sid=${contentsession_id}&bg_color=eaeaea&follow_auto=1&${getTranslationOrTempId("MChair", servicesList)}#/mlook`}
              showCopyButton
              description={appText?.txtAppSpeechTimerDesc}
              setSnackbarOpen={setIsOpen}
              showQrCode={showQrCode}
            />
        </SectionContainer>

        {/* Speakers */}
        {filteredAuthors?.length > 0 && (
          <SectionContainer id="speakers" title={pageTitle.selectContentSessionPage.speakers}>
              {filteredAuthors?.map((author, index) => (
                <CardWithIcon
                  key={index}
                  title={appText?.txtAppRemoteSpeakerTitle}
                  id={`MBox_Remote_Speaker_${index}`}
                  subtitle={`${author?.firstname} ${author?.surname}`}
                  icon="fas fa-house-signal fa-fw"
                  link={`${faculty}?sid=${contentsession_id}&follow_modal=1&u_id=${author.id}`}
                  showCopyButton
                  setSnackbarOpen={setIsOpen}
                  showQrCode={showQrCode}
                />
              ))}
          </SectionContainer>
        )}

        {/* Chairs */}
        <SectionContainer id="chairs" title= {pageTitle.selectContentSessionPage.chairs}>
            <CardWithIcon
              title={appText?.txtAppDefaultChairTitle}
              description={appText?.txtAppDefaultChairDesc}
              id="MBox_Local_chair"
              subtitle="Chair"
              icon="fas fa-podium fa-fw"
              link={`${faculty}?sid=${contentsession_id}&autoConnect=1&follow_auto=1&SpeakerFullName=Chair&follow_modal=1`}
              showCopyButton
              setSnackbarOpen={setIsOpen}
              showQrCode={showQrCode}
            />
            {data?.chairs?.map((chair, index) => (
              <CardWithIcon
                key={index}
                title={appText?.txtAppRemoteChairTitle}
                id={`MBox_Local/Remote_Chair_${index}`}
                subtitle={`${chair.firstname} ${chair.surname}`}
                icon="fas fa-house-signal fa-fw"
                showCopyButton
                setSnackbarOpen={setIsOpen}
                link={`${faculty}?sid=${contentsession_id}&chair_user_id=${chair.id}`}
                showQrCode={showQrCode}
              />
            ))}
            {!generalConfig?.hideOutsideChairs && data?.chairs?.map((chair, index) => (
              <CardWithIcon
                key={index}
                title={appText?.txtAppChairNamesTitle}
                id={`MBox_Outside_Local_Chair_${index}`}
                subtitle={`${chair.firstname} ${chair.surname}`}
                icon="fas fa-podium-star fa-fw"
                showCopyButton
                description={appText?.txtAppChairNamesDesc}
                setSnackbarOpen={setIsOpen}
                link={`${faculty}?sid=${contentsession_id}&chair_name=${
                  chair.firstname
                } ${chair.surname}&follow_modal=1${getTranslationOrTempId(
                  "MChairNames",
                  servicesList
                )}#/mlook`}
                showQrCode={showQrCode}
              />
            ))}
        </SectionContainer>

        <SectionContainer title= {pageTitle.selectContentSessionPage.others}>
            <CardWithIcon
              title={appText?.txtAppProjectorTitle}
              id="MBox_Projector"
              icon="fas fa-projector fa-fw"
              link={`${faculty}?sid=${contentsession_id}&follow_auto=1${getTranslationOrTempId(
                "HdProjector",
                servicesList
                )}&${addParamsToQs(
                    servicesList?.HdProjector?.linkQsParams
                    )}#hd-designer`}
              description={appText?.txtAppProjectorDesc}
              showCopyButton
              setSnackbarOpen={setIsOpen}
              showQrCode={showQrCode}
            />
            <CardWithIcon
              title={appText?.txtAppMLookTitle}
              id="MBox_mLook"
              icon="fas fa-telescope fa-fw"
              // link={`https://demo.conference2web.com/e-signage-x/2023/v1/?room_id=${get("room_id")}`}
              // link={`https://demo.conference2web.com/e-signage-x/2023/v1/?token=${deflate64({room_id:get("room_id")})}`}
              link={`${servicesList["MLook"]?.link}/?${
                servicesList["MLook"]?.isUrlEncrypted
                  ? `token=${deflate64({ room_id: get("room_id") })}`
                  : `room_id=${get("room_id")}`
              }`}
              description={appText?.txtAppMLookDesc}
              showCopyButton
              setSnackbarOpen={setIsOpen}
              showQrCode={showQrCode}
            />
        </SectionContainer>
        
        <SectionContainer title="OneSite Control Area" >
          <CardWithIcon
                  title={appText?.txtAppQnAModeratorTitle}
                  id="MBox_StreamingAdmin"
                  icon="fal fa-comment-alt-edit fa-fw"
                  // link={`${streamingAdminUrl}?sid=${contentsession_id}`}
                  link={`${qnaModeratorUrl}?${Base64.encode("sid")}=${Base64.encode(contentsession_id)}`}
                  description={appText?.txtAppQnAModeratorDesc} 
                  showCopyButton
                  setSnackbarOpen={setIsOpen}
                  showQrCode={showQrCode}
                />
            <CardWithIcon
                  title={appText?.txtAppStreamingAdminTitle}
                  id="MBox_StreamingAdmin"
                  icon="fas fa-tools fa-fw"
                  // link={`${streamingAdminUrl}?sid=${contentsession_id}`}
                  link={`${streamingAdminUrl}?${Base64.encode("sid")}=${Base64.encode(contentsession_id)}`}
                  description={appText?.txtAppStreamingAdminDesc} 
                  showCopyButton
                  setSnackbarOpen={setIsOpen}
                  showQrCode={showQrCode}
                />
            <CardWithIcon
                title={appText?.txtAppStreamingLobbyTitle}
                id="MBox_Lobby"
                icon="fas fa-tools fa-fw"
                // link={`${streamingAdminUrl}?sid=${contentsession_id}`}
                link={`${lobbyUrl}?token=${deflate64({"VmSessionId":contentsession_id,"tech":1})}`}
                showCopyButton
                description={appText?.txtAppStreamingLobbyDesc} 
                setSnackbarOpen={setIsOpen}
                showQrCode={showQrCode}
              />
        </SectionContainer>

        {/* Cameras Room */}
        <SectionContainer id="cameras" title= {pageTitle.selectContentSessionPage.camerasRoom}>
            <CardWithIcon
              title={appText?.txtAppMBoxSpeakerCamTitle}
              id="Speaker"
              icon="fas fa-podium"
              link={`${faculty}?autoConnect=1&follow_auto=1&sid=${contentsession_id}&local_cam_only=1&autojoinAs=Cam_Speaker#/`}
              showCopyButton
              setSnackbarOpen={setIsOpen}
              showQrCode={showQrCode}
            />
            <CardWithIcon
              title={appText?.txtAppMBoxChairsCamTitle}
              id="Chairs"
              icon="fas fa-loveseat fa-fw"
              link={`${faculty}?autoConnect=1&follow_auto=1&sid=${contentsession_id}&local_cam_only=1&autojoinAs=Cam_Chairs#/`}
              showCopyButton
              setSnackbarOpen={setIsOpen}
              showQrCode={showQrCode}
            />
            <CardWithIcon
              title={appText?.txtAppMBoxAttendeesCamTitle}
              id="MBox_Attendees"
              icon="fas fa-users fa-fw"
              link={`${faculty}?autoConnect=1&follow_auto=1&sid=${contentsession_id}&local_cam_only=1&autojoinAs=Cam_Attendees#/`}
              showCopyButton
              setSnackbarOpen={setIsOpen}
              showQrCode={showQrCode}
            />
        </SectionContainer>
      </div>
    </Page>
  );
};

export default SelectContentsession;
