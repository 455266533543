import "./ImagePreview.css";
import React from "react";

const ImagePreview = ({ onClick, text="Feature preview" }) => {
  return (
    <div className="feature-container">
      <span className="feature-text">{text}</span>
      <i className="feature-icon fas fa-image" onClick={onClick}></i>
    </div>
  );
};

export default ImagePreview;
