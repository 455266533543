import "react-toastify/dist/ReactToastify.css";
//import "react-tooltip/dist/react-tooltip.css";
import "./App.scss";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import routes from "./config/routes.json";

import SelectContentsession from "./pages/selectContentsessionPage/SelectContentsession";
import ContentSession from "./pages/contentSessionPage/ContentSession";
import Home from "./pages/home/Home";
import MBoxes from "./pages/mboxesPage/MBoxes";
import EventsList from "./pages/events/EventsList";
import OnSite from "./pages/onSite/OnSite";
import Live from "./components/Live/Live";
import Oca from "./pages/oca/Oca";
import Services from "./pages/services/Services";
import AppsConfig from "./pages/appsConfig/AppsConfig";
import Page from "./components/common/Page";
import MBoxContext from "./context/MBoxContext";
import Header from "./components/header/Header";
import RedirectPage from "./pages/redirectPage/RedirectPage";
import JmtLogin from "./pages/jmtLogin/JmtLogin";
import AuthCheck from "./pages/authCheck/AuthCheck";
import ClearAndLogin from "./pages/clearAndLogin/ClearAndLogin";
import { useContext } from "react";
import { Tooltip } from "react-tooltip";

function App() {
  
  const [isChecking, setIsChecking] = useState(true);
  const {
    onSetAppId,
    isLogin,
    setIsLogin
  } = useContext(MBoxContext);

  useEffect(() => {
    setIsChecking(true);
    const accessToken = localStorage.getItem("access_token");
    const appId = localStorage.getItem("app_id");
    const userRole = localStorage.getItem("role");
    const expiredToken = localStorage.getItem("expires_at");
    const isTokenExpired = new Date(expiredToken) < new Date()

    if (!!accessToken && !!appId && !!userRole  && !!expiredToken && !isTokenExpired) {
      setIsLogin(true);
    }else{
      setIsLogin(false);
      localStorage.removeItem("access_token");
      localStorage.removeItem("role");
      localStorage.removeItem("expires_at");
    }

    setIsChecking(false);
  }, []);

  
  if (isChecking) {
    return null;
  }

  return (
    <>
      <ToastContainer />
      <Tooltip id="my-tooltip" className="extraClass" style={{ backgroundColor: "#3D91F9", color: "#E3F0FF", zIndex:10 }} />
      <Tooltip id="my-black-tooltip" className="extraClass" style={{ backgroundColor: "rgba(0,0,0, .7)", color: "#E3F0FF", zIndex:10 }} />
      {!isLogin ? (
        <Page>
          <Switch>
            <Route exact path={routes.HOME}>
              <Home />
            </Route>
            <Route exact path={routes.REDIRECT}>
              <RedirectPage setIsLogin={setIsLogin} onSetAppId={onSetAppId} />
            </Route>
            <Route exact path={routes.REDIRECTLOGOUT}>
              <ClearAndLogin setIsLogin={setIsLogin} onSetAppId={onSetAppId} />
            </Route>
            <Route exact path="/auth/resetPassword">
              <JmtLogin />
            </Route>
            <Route exact path={routes.LOGIN}>
              <JmtLogin />
            </Route>
            <Route exact path={routes.AUTHCHECK}>
              <AuthCheck />
            </Route>
            <Route path={routes.OCA}>
              <Oca onSetAppId={onSetAppId} />
            </Route>
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Page>
      ) : (
        <>
          <Header setIsLogin={setIsLogin} />
          <Page>
            <Switch>
            <Route path={routes.OCA}>
                <Oca onSetAppId={onSetAppId} />
              </Route>
              <Route exact path={routes.REDIRECT} isLogin={isLogin}>
                <RedirectPage setIsLogin={setIsLogin} />
              </Route>
              <Route exact path={routes.REDIRECTLOGOUT}>
                <ClearAndLogin
                  setIsLogin={setIsLogin}
                  onSetAppId={onSetAppId}
                />
              </Route>
              <Route exact path={routes.SERVICES}>
                <Services />
              </Route>
              <Route path={routes.EVENTS}>
                <EventsList />
              </Route>
              <Route path={routes.MBOX}>
                <MBoxes />
              </Route>
              <Route path={routes.SESSIONS}>
                <ContentSession />
              </Route>
              <Route path={routes.SESSION_SELECT}>
                <SelectContentsession />
              </Route>
              <Route path={routes.LIVE}>
                <Live />
              </Route>
              <Route path={routes.APPS_CONFIG}>
                <AppsConfig />
              </Route>
              <Route path={routes.ONSITE}>
                <OnSite />
              </Route>
              <Route>
                <Redirect to={`${routes.EVENTS}`} />
              </Route>
              
            </Switch>
          </Page>
        </>
      )}
    </>
  );
}

export default App;
