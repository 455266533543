import "./FullScreenMessage.scss";
import React from 'react';
import Backdrop from './Backdrop';

const FullScreenMessage = ({show, zIndex, text, ...rest}) => {
    let z_Index = zIndex || 100;
    return ( <div className="m-box-message-fs">
        <Backdrop show={show} zIndex={z_Index} />
        {text && <div className="m-box-message-fs__text" style={{zIndex:z_Index}}>{text}</div>}
    </div> );
}
 
export default FullScreenMessage;