import React from "react";
import BackButton from "./BackButton";

const Message = ({ showBackBtn, onBackBtnClick, txtBackBtn, children }) => {
  return (
    <div className="mbox-message py-3 px-4">
      {showBackBtn && (
        <div>
          <BackButton className="test" onClick={onBackBtnClick} />
        </div>
      )}
      <div className="mbox-message__content">{children}</div>
    </div>
  );
};

Message.defaultProps = {
  showBackBtn: true,
  txtBackBtn: "Back to services",
};

export default Message;
