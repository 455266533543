import React from "react";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router
} from "react-router-dom";
import "./index.css";
import App from "./App";
import { MBoxProvider } from "./context/MBoxContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <MBoxProvider>
      <App />
    </MBoxProvider>
  </Router>
);
