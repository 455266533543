import React, { useEffect } from 'react';
import  {Tooltip as ReactTooltip} from 'react-tooltip';


const AddReactToolTip = () => {
    useEffect(() => {
        // ReactTooltip.rebuild();
      });
    return  <ReactTooltip id="my-tooltip" className="extraClass" style={{ backgroundColor: "#3D91F9", color: "#E3F0FF", zIndex:10 }} />;
}
 
export default AddReactToolTip;