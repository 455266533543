import React, {useState, useEffect} from "react";
import "./contentSession.css";
import ContentSessionCard from "../../components/contentSessionCard/ContentSessionCard";
import LoadingSpinner from "../../components/LoadingSpinner";
import useApiHook from "../../hooks/useApiHook";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import BootstrapScreenSize from "../../utils/BootstrapSceensize";
import MBoxContext from "../../context/MBoxContext";
import { useContext } from "react";
import { store } from "../../utils/SetLocalstorage";
import { LocalstorageContentsessionConfig } from "../../config/LocalstorageConfig";
import Page from "../../components/common/Page";
import Searchbar from "../../components/searchbar/Searchbar";
import { doSearch } from "../../utils/AppHelpers";
import Alert from "../../components/common/Alert";

const ContentSession = () => {
  const {pageTitle,fieldsName, baseUrl} = useContext(MBoxContext)
  const [searchInput, setSearchInput] = useState("")
  const [isContentSessionChange,setIsContentSessionChange] = useState(false);
  const url = `https://${baseUrl}/v1/rooms/${localStorage.getItem(
    "room_id"
  )}.json?include_all_contentsessions=1&use_cache=0`;

  const { data, isLoading } = useApiHook(url);
  const [resultData, setResultData] = useState(null);
  const { width } = useWindowDimensions();
  const { lg } = BootstrapScreenSize;
  const {
    CONTENTSESSION_ID,
    CONTENTSESSION_TITLE,
    CONTENTSESSION_STARTSAT,
    CONTENTSESSION_ENDSAT,
    CONTENTSESSION
  } = LocalstorageContentsessionConfig;

  const setLocalstorageContentsession = (session) => {
    store(CONTENTSESSION_ID, session.id);
    store(CONTENTSESSION_TITLE, session.title);
    store(CONTENTSESSION_STARTSAT, session.starts_at_local);
    store(CONTENTSESSION_ENDSAT, session.ends_at_local);
    store(CONTENTSESSION, session);
  }

  useEffect(() => {
    if(isContentSessionChange){
      setIsContentSessionChange(false);
      window.location.reload(true);
    }
    setResultData(data?.contentsessions)
  }, [data, isContentSessionChange])

  useEffect(() => {
    if(data){
      setResultData(doSearch(data?.contentsessions, searchInput));
    }
   
  }, [searchInput])

  if (isLoading) {
    return <LoadingSpinner />;
  }
  const hasSessions =resultData?.length > 0;
  return (
    <Page name="sessions-page">
      {/* <Header /> */}
      <div className="contentSessionPage-container">
        <div className="contentSessionPage-title-container">
          <p className="mbox-headline-large">{pageTitle.contentsessionPage}</p>
        </div>
       <Searchbar setInput={setSearchInput}
            searchInput={searchInput}
            searchbarPlaceholder="Please Enter Title or Content Session Id ..."/>
        {hasSessions && width > lg && (
          <div className="row contentSessionPage-formTitle-container mbox-headline-medium">
            <div className="col-1 contentSessionPage-title-item-container">
            Session <br></br> From
            </div>
            <div className="col-1 contentSessionPage-title-item-container">
            Session <br></br> To
            </div>
            <div className="col-7 contentSessionPage-title-item-container">
            Session <br></br> Title
            </div>
            <div className="col-1 contentSessionPage-title-item-container">
              {fieldsName.ID}
            </div>
          </div>
        )}
        <div>
        {!hasSessions && (
          <Alert type="info" size="m">No sessions found in this mBox/room...</Alert>
        )}
          {resultData?.map((session, index) => (
            <ContentSessionCard
            setIsContentSessionChange={setIsContentSessionChange}
              showTime={true}
              key={index}
              ID={session?.id}
              title={session?.title}
              startDate={session?.starts_at_local}
              endDate={session?.ends_at_local}
              // link={`select_contentsession?id=${session?.id}&title=${session?.title}&starts_at=${session?.starts_at_local}&ends_at=${session?.ends_at_local}`}
              link="select_contentsession"
              showEdit={true}
              onClick={() => setLocalstorageContentsession(session)}
            />
          ))}
        </div>
      </div>
    </Page>
  );
};

export default ContentSession;
