import routes from "./../config/routes.json";
export const AppSessionConfig = {
    isConfigLoaded:false,

    tempIdSpeechTimer:7724,
    tempIdLectern:7726,
    tempIdChairNames:7728,
    tempIdHdDesigner:7731
};

export const AppTypes ={
    MBox:"MBox",
    QnA:"QnA",
    MAttach:"MAttach",
    MAirport:"MAirport",
    MTV:"MTV",
    VirtualMeeting1:"VirtualMeeting1",
    VirtualMeeting2:"VirtualMeeting2"
};

export const breadcrumbOptions = {
  items: [
    {
      to: routes.EVENTS,
      label: "Events",
      tooltipName:"Events",
      tooltips: [
        { store: "event_title", label: "Event Title" },
        { store: "event_id", label: "Event ID" },
        { store: "event_starts_at", label: "Starts At" },
        { store: "event_ends_at", label: "Ends At" },
      ],
    },
    {
      to: routes.SERVICES,
      label: "Services",
      tooltipName:"Services",
      tooltips: [],
    },
    {
      to: routes.MBOX,
      label: "M Box List",
      tooltipName:"M_Box_List",
      tooltips: [
        { store: "m_box_id", label: "M Box ID" },
        { store: "m_box_name", label: "M Box Name" },
        { store: "room_id", label: "Room ID" },
        { store: "room_title", label: "Room Title" }
      ],
    },
    {
      to: routes.SESSIONS,
      label: "Session List",
      tooltipName:"Content_Session_List",
      tooltips: [
        { store: "contentsession_id", label: "Contentsession ID" },
        { store: "contentsession_title", label: "Contentsession Title" },
        { store: "contentsession_starts_at", label: "Starts At" },
        { store: "contentsession_ends_at", label: "Ends At" }
      ],
    },
    {
      to: routes.SESSION_SELECT,
      label: "M Box",
      tooltipName:"M_Box",
      tooltips: [],
    },
  ],
};
