import React from "react";
import momentTZ from "moment-timezone";

const SelectTimeZones = (props) => {
  return (
    <select className="form-control" value={props.value} onChange={(e) => props.onChange(e.target.value)}>
      {momentTZ.tz.names().map((c) => (
        <option key={c}>{c}</option>
      ))}
    </select>
  );
};

export default SelectTimeZones;
