/* eslint-disable default-case */
import _ from "lodash";
import { AppTypes } from "../store/config";
import { deflate64 } from "./PakoHelper";

export const addParamsToBaseAppLink = (pAppType, baseLink, pEventId, isUrlEncrypted) => {
  let lFullLink = baseLink;
  switch (pAppType) {
    case AppTypes.QnA:
      // lFullLink = `${lFullLink}?event_id=${pEventId}`;
      lFullLink = `${lFullLink}?${isUrlEncrypted ? (`token=${deflate64({event_id:pEventId})}`):(`event_id=${pEventId}`) }`
      break;
    case AppTypes.MAttach:
      lFullLink = `${lFullLink}?touch=true&eventid=${pEventId}`;
      break;
    case AppTypes.MAirport:
      //lFullLink = `${lFullLink}?id=mlook-airport--ticker-and-twitter&args=event_id:${pEventId};isDemo:false&viewMode=story`;
      // lFullLink = `${lFullLink}?event_id=${pEventId}&room_id1=2721&is_demo1=0`;
      // lFullLink = `${lFullLink}?token=${deflate64({event_id:pEventId})}`
      lFullLink = `${lFullLink}?${isUrlEncrypted ? (`token=${deflate64({event_id:pEventId})}`):(`event_id=${pEventId}`) }`
      break;
    case AppTypes.MTV:
      lFullLink = `${lFullLink}?event_id=${pEventId}`;
      break;
  }
  return lFullLink;
};

// This function will be used to change normal url params into encrypted token
export const convertUrlToEncrypt = (link) => {
  const extractParams = link.split("?")[1];
  const isOneParam = extractParams.includes("&")
  let token = {}

  if(!isOneParam){
    const key = extractParams.split("=")[0]
    const value = extractParams.split("=")[1]
    token = {...token, [key]:value}
  }else{
    const paramsArray = extractParams.split("&")
    for(let param in paramsArray){
      const key = paramsArray[param].split("=")[0]
      const value = paramsArray[param].split("=")[1]

    token = { ...token, [key]: value };
    }
  }
  return link.split("?")[0] + `?token=${deflate64(token)}`
}

const merge = (obj1, obj2) => {
  Object.keys(obj2).forEach(function (key) {
    if (key in obj1) {

      // if(Array.isArray(obj1[key]) || Array.isArray(obj2[key])){
      //   console.log(obj2[key])
      //   if(Array.isArray(obj2[key])) obj1[key] = obj2[key];
      //   else ;
      // } else 
      if (typeof obj1[key] === "object") {
        if(obj1[key] === null) 
          obj1[key] = obj2[key];
        else  
          merge(obj1[key], obj2[key]);
      } else {
        obj1[key] = obj2[key];
      }
    }
  });
};
/**
 * Merged object has only props from obj1, with overriden values from obj2
 * @param {*} obj1
 * @param {*} obj2
 * @returns new object(obj1 && obj2 are not mutated)
 */
export const mergeExisting = (obj1, obj2) => {
  const mutableObject1 = Object.assign({}, obj1);
  const mutableObject2 = Object.assign({}, obj2);

  merge(mutableObject1, mutableObject2);

  return mutableObject1;
};

function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * is a recursive function that merges two objects.
 * If both values are objects, it calls itself recursively.
 * It only assigns values from the source object if the key exists in the target object, handling nested structures.
 * Credits: Chat GPT 4
 * @param {*} target 
 * @param {*} source 
 * @returns 
 */
export const mergeDeepExistingv2 = (target, source) =>{
  let output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
      Object.keys(source).forEach(key => {
          if (isObject(source[key])) {
              if (!(key in target))
                  Object.assign(output, { [key]: source[key] });
              else
                  output[key] = mergeDeepExistingv2(target[key], source[key]);
          } else if (target.hasOwnProperty(key)) {
              Object.assign(output, { [key]: source[key] });
          }
      });
  }
  return output;
}

/**
 * merges keys from both target and source.
 * If a key exists in source but not in target, it will be added to the merged result.
 * The logic for handling arrays and nested objects remains the same as previously described.
 * Credits: Chat GPT 4
 * @param {*} target 
 * @param {*} source 
 * @returns 
 */
export const mergeDeepIncludeAllKeys = (target, source)=> {
  let output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
      Object.keys(source).forEach(key => {
          if (Array.isArray(source[key])) {
              output[key] = source[key];
          } else if (isObject(source[key])) {
              if (!(key in target)) {
                  Object.assign(output, { [key]: source[key] });
              } else {
                  output[key] = mergeDeepIncludeAllKeys(target[key], source[key]);
              }
          } else {
              output[key] = source[key];
          }
      });

      // Add keys from the target that are not in the source
      Object.keys(target).forEach(key => {
          if (!(key in source)) {
              output[key] = target[key];
          }
      });
  }
  return output;
}

export const printPretty = (pJsonData) =>
  JSON.stringify(pJsonData, undefined, 2);

export const sortByKeys = (object) => {
  if(!object)
  return object;
  const keys = Object.keys(object);
  const sortedKeys = _.sortBy(keys);

  return _.fromPairs(
    _.map(sortedKeys, (key) => {
      let value = object[key];
      if (typeof object[key] === "object" && !(object[key] instanceof Array)) {
        value = sortByKeys(value);
      }
      return [key, value];
    })
  );
};

export const doSearch = (data, searchInput) => {
  
  if (searchInput?.length && data) {
    const filteredData = data.filter((singleEvent) =>
      singleEvent?.title
        .toString()
        .toLowerCase()
        .includes(searchInput.toLowerCase())
    );

    const filterIdData = data.filter((singleEvent) =>
      singleEvent.id.toString().includes(searchInput)
    );

    const mergedData = _.union(filteredData, filterIdData);

    return mergedData;
  } else {
    return data;
  }
};

export const  getRndInteger =(min, max)=> {
  return Math.floor(Math.random() * (max - min + 1) ) + min;
}
