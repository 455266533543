//import { getVmBaseUrl } from "../utils/Helpers";
import { makeHttpCall } from "../utils/HttpHelpers";
import { getBaseUrl as getVmBaseUrl, getEventId, getSessionId, getRoomId } from "./../utils/QueryString";


export const qs_show_results = async (id) => {
  const url = `https://${getVmBaseUrl()}/v1/questions/${id}.json`;
  const result = await makeHttpCall({
    url,
    method: "PUT",
    bodyJson: { id, mode: "show_result" },
    isVm: true,
  });
  return result;
};

export const get_translations = async ({eventId, roomId, sessionId, translation_key, value }) => {
  //let baseUrl = "api-lb.virtual-meeting.net";
  let test = getVmBaseUrl();
  //debugger

  const url = `https://${getVmBaseUrl()}/v1/translationkey_mappings.json`;
  let translatable_type = null;
  let translatable_id = null;
  
  if (eventId) {
    translatable_type = "Event";
    translatable_id = eventId;
  }
  if (roomId) {
    translatable_type = "Room";
    translatable_id = roomId;
  }
  if (sessionId) {
    translatable_type = "Contentsession";
    translatable_id = sessionId;
  }
  
  if(!translatable_type || !translation_key)
  return  new Promise((resolve, reject) => {
    resolve(null);
  });
  const params = {
    translation_language: translation_key,//"mbox.landing.config",
    include_translations: 1,
    include_translatable: 1,
    translatable_type,
    translatable_id,
  };
  let method = "GET";
  let headers = {};
  let bodyJson = null;
  let lUrl =  `${url}?${Object.keys(params).map(key=>`${key}=${params[key]}`).join('&')}`;
  if(value){
    lUrl =  `${url}`;
    method ="POST";
    //params.value = typeof value === "object" ?  JSON.stringify(value): value;
    bodyJson = new URLSearchParams();
    bodyJson.append('language', translation_key);
    bodyJson.append('translatable_type', translatable_type);
    bodyJson.append('translatable_id', translatable_id);
    bodyJson.append('value', JSON.stringify(value));
    
  }
  const result = await makeHttpCall({
    url : lUrl,
    method,
    headers,
    bodyJson,
    isVm: true,
  });
  return result;
};

export const getTranslationOrTempId = (name, servicesList) => {
  
  if(!servicesList?.[name]) return null;

  if(servicesList[name]?.translation_key) return `&trans_lang=${servicesList[name].translation_key}`;

  return `&temp_id=${servicesList[name].tempId}`;
};

export const addParamsToQs = (paramsObj) => {
  try {
    if (!paramsObj || typeof paramsObj !== "object") return "";
    return Object.keys(paramsObj)
      .map((c) => `${c}=${paramsObj[c]}`)
      .join("&");
  } catch (err) {console.log(err);}
  return "";
};

export const capitalizeFirstLetter = (str) => {
  const removeUnderscores = str.replace(/_/g, " ");

  const capitalizeStr = removeUnderscores.charAt(0).toUpperCase() + removeUnderscores.slice(1);

  return capitalizeStr;

}