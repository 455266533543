import React from 'react';
import "./Backdrop.css";

const Backdrop = (props) => {

    return props.show ? <div className="Backdrop" style={{zIndex:props.zIndex}}></div> :null
}
Backdrop.defaultProps = {
    zIndex:100
}; 
export default Backdrop;