import "./CustomCheckboxField.scss";
import React, { useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { isUndefined } from "lodash";
import ImagePreview from "./../customWidgets/common/ImagePreview";

const CustomCheckboxField = (props) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const previewImg = props?.schema?.previewImg;
  //console.log(props);
  return (
    <div className="mbox-checkbox-field-container">
      {previewImg && isViewerOpen && (
        <ImageViewer
          src={[previewImg]}
          disableScroll={false}
          closeOnClickOutside={true}
          backgroundStyle={{ backgroundColor: "rgba(0,0,0,.7)", zIndex: 10 }}
          onClose={() => setIsViewerOpen(false)}
        />
      )}
      <label className="mbox-checkbox-field-container__input">
        <input
          id={props?.id}
          type="checkbox"
          checked={!isUndefined(props?.formData) ? props?.formData : false}
          onChange={(event) => {
            console.log(event.target.checked);
            props.onChange(event.target.checked);
          }}
        />
        <span>{props?.schema?.title}</span>
      </label>
      {previewImg && <ImagePreview onClick={() => setIsViewerOpen(true)} />}
    </div>
  );
};

export default CustomCheckboxField;
