import React from "react";

const BackButton = ({ txtBackBtn, className, children, ...rest }) => {
  const cssClasses =["btn btn-outline button-mbox-warning"];
  if(className)
    cssClasses.push(className);
  return (
    <button className={cssClasses.join(" ")} {...rest}>
      <i className="fas fa-chevron-left mr-2"></i>
      {txtBackBtn}
    </button>
  );
};
BackButton.defaultProps = {
  txtBackBtn: "Back to services",
};

export default BackButton;
