import React from "react";
import { v4 as uuidv4 } from 'uuid';

const SectionContainer = ({ id, title, children, showAdditionInfo }) => {
  return (
    <div id={id || uuidv4()} className="section-container">
      <div className="section-title-container">
        <span className="section-title">{title}</span>
        {showAdditionInfo && (
          <div style={{display:"flex"}}>
            <div className="m-2" style={{fontSize:"14px", color:"gray"}}>
              <span style={{ color: "green", fontWeight: "bold" }}>---- </span>
              <span>
                :  <b>is</b> uploaded
              </span>
            </div>
            <div className="m-2" style={{fontSize:"14px", color:"gray"}}>
              <span style={{ color: "red", fontWeight: "bold" }}>---- </span>
              <span>
                : <b>not</b> uploaded
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="row">{children}</div>
    </div>
  );
};

export default SectionContainer;
