const LocalstorageEventConfig = {
    EVENT_ID: "event_id",
    EVENT_TITLE:"event_title",
    EVENT_STARTSAT:"event_starts_at",
    EVENT_ENDSAT:"event_ends_at"
}

const LocalstorageContentsessionConfig = {
    CONTENTSESSION_ID:"contentsession_id",
    CONTENTSESSION_TITLE:"contentsession_title",
    CONTENTSESSION_STARTSAT:"contentsession_starts_at",
    CONTENTSESSION_ENDSAT:"contentsession_ends_at",
    CONTENTSESSION:"CONTENTSESSION"
}

const LocalstorageMBoxConfig = {
    MBOX_ID:"m_box_id",
    MBOX_NAME:"m_box_name",
    ROOM_ID:"room_id",
    ROOM_TITLE:"room_title"
}

export {LocalstorageEventConfig, LocalstorageContentsessionConfig,LocalstorageMBoxConfig};