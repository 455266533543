import "./CustomRange.css";
import React, {useState} from "react";
import ImageViewer from "react-simple-image-viewer";
import { isUndefined } from "lodash";
import ImagePreview from './common/ImagePreview';

const CustomRange = function (props) {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  //console.log(props);
  const previewImg = props?.schema?.previewImg;
  return (
    <div className="custom-range-container">
      {previewImg && isViewerOpen && (
        <ImageViewer
          src={[previewImg]}
          disableScroll={false}
          closeOnClickOutside={true}
          backgroundStyle={{ backgroundColor: "rgba(0,0,0,.7)", zIndex: 10 }}
          onClose={() => setIsViewerOpen(false)}
        />
      )}
      <input
        type="range"
        className="custom-range-container__input"
        value={!isUndefined(props.value) ? props.value :""}
        onChange={(e) => props.onChange(e.target.value)}
        min={props?.schema?.minimum}
        max={props?.schema?.maximum}
      />
      <span className="custom-range-container__value badge badge-pill badge-primary">{props.value}</span>
      {previewImg && (
          <ImagePreview onClick={()=>setIsViewerOpen(true)}/>
        )}
    </div>
  );
};

export default CustomRange;
