import React from "react";
import { useEffect } from "react";
import routes from "../../config/routes.json"
import Breadcrumbs from "../breadcrumps/Breadcrumbs";
import "./Header.css";
import { useLocation, useHistory} from "react-router-dom";

const Header = (props) => {
  const {setIsLogin} = props;
  const location = useLocation();
  const history = useHistory();

  const logoutAction = () => {
    history.push({
      pathname:`/apps/${localStorage.getItem("app_id")}/login`
    })
  }

  if (
    location?.pathname === "/" ||
    location.pathname === "/apps_config" ||
    location.pathname === "/auth/login" ||
    location.pathname === "/apps/:id" ||
    location.pathname === "/auth/check/:id"
  )
    return null;

  return (
    <div className="header-container">
      <div className="header-container__logo-container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: 0,
          }}
        >
          <img alt="Logo" src="/images/OneSite_Logo.svg" />
          <span
            className=" ml-2"
            style={{ fontWeight: 600, letterSpacing: "2px", fontSize: "26px" }}
          >
            {" "}
            · Selectah
          </span>
        </div>
        <button
          type="button"
          className="header-logout-button"
          onClick={() => {logoutAction()
            // localStorage.removeItem("app_id");
            // localStorage.removeItem("role");
            // localStorage.removeItem("event_id");
            // localStorage.removeItem("event_title");
            // localStorage.removeItem("user_name");
            // localStorage.removeItem("event_ends_at");
            // localStorage.removeItem("event_starts_at");
            // localStorage.removeItem("access_token");
            // localStorage.removeItem("mboxSeletah.user");
            // window.location.reload();
          }}
        >
          Logout
        </button>
      </div>
      <div className="container">
        <Breadcrumbs />
      </div>
    </div>
  );
};

export default Header;
