import React, { useState, useEffect, useLayoutEffect } from "react";
import _ from "lodash";
import { 
  MBoxApps, 
  FACULTY_BASE_URL, 
  STREAMING_ADMIN_BASE_URL,
  QNA_MODERATOR_BASE_URL,
  LOBBY_BASE_URL,
  Pages,
  AppText,
  InputFields,
  GeneralConfig } from "./appConfiguration";
import Page from "../components/common/Page";
import { makeHttpCall } from "../utils/HttpHelpers";
import { get } from "../utils/SetLocalstorage";
import { isHexColor } from "../utils/Validator";
import { getBaseUrl } from "../utils/QueryString";

const MBoxContext = React.createContext();

export const MBoxProvider =(props)=> {
  const { children } = props;

  const [baseUrl, setBaseUrl] = useState("api-lb.virtual-meeting.net");
  const [facultyUrl , setFacultyUrl] = useState(FACULTY_BASE_URL);
  const [showQrCode , setShowQrCode] = useState(false);
  const [lobbyUrl , setLobbyUrl] = useState(LOBBY_BASE_URL);
  const [streamingAdminUrl , setStreamingAdminUrl] = useState(STREAMING_ADMIN_BASE_URL);
  const [qnaModeratorUrl , setQnaModeratorUrl] = useState(QNA_MODERATOR_BASE_URL);

  const [vmAppId , setVmAppId] = useState(null);
  const [isLogin, setIsLogin] = useState(false);

  //should be event level
  const [servicesList, setServicesList] = useState(MBoxApps);
  const [generalConfig, setGeneralConfig] = useState(GeneralConfig);
  const [fieldsName, setFieldsName] = useState(InputFields);
  const [pageTitle, setPageTitle] = useState(Pages);
  const [appText, seAppText] = useState(AppText);
  const [contentSessionSettingsField, setContentSessionSettingsField] = useState([])
  const [color, setColor] = useState(null);
  const [loading, setLoading] = useState(true);
  
  
  useEffect(() => {
    (async () => {
      if(!isLogin) return;
      const appId = get("app_id");
      const url = `https://${getBaseUrl()}/v1/translationkey_mappings.json?include_translations=1&include_translatable=1&translation_language=mbox.landing.config&translatable_type=App&translatable_id=${appId}&smart_find=0`;

      setLoading(true);
      const apiRes = await makeHttpCall({
        url: url,
        method: "GET",
        isVm: true,
      });
      if (apiRes?.ok) {
        const value = JSON.parse(
          apiRes.data[0].translationkey.translations[0].value
        );
        setShowQrCode(value?.showQrCode || false);
        if (value?.facultyBaseUrl) setFacultyUrl(value?.facultyBaseUrl);
        if (value?.lobbyBaseUrl) setLobbyUrl(value?.lobbyBaseUrl);
        if (value?.streamingAdminBaseUrl)
          setStreamingAdminUrl(value?.streamingAdminBaseUrl);
        if (value?.qnaModeratorBaseUrl)
          setQnaModeratorUrl(value?.qnaModeratorBaseUrl);
        if (value?.text)
          seAppText((prev) => {
            return _.merge(prev, value?.text);
          });
        if (value?.generalConfig)
          setGeneralConfig((prev) => {
            return _.merge(prev, value?.generalConfig);
          });

        let lPageTitle = {}; // 2 different/possible sources for pages(legacy)
        if (value?.pageTitle) lPageTitle = { ...value?.pageTitle };
        if (value?.text?.pages) lPageTitle = { ...value?.text?.pages };
        setColor(value?.color);
        if(value?.fieldsName) setFieldsName(prev => {return { ...prev, ...value?.fieldsName }});
        setPageTitle({ ...pageTitle, ...lPageTitle });
        // setPageTitle(lPageTitle, _.merge({...pageTitle}));
        if (value?.servicesList)
          setServicesList((prev) => {
            return _.merge(prev, value?.servicesList);
          });

        if (value?.contentSessingSettingsField) {
          setContentSessionSettingsField((prev) => {
            return [...prev, ...value?.contentSessingSettingsField];
          });
        }
      }
      setLoading(false);
    })();
  }, [isLogin]);


  useEffect(()=> {
    console.log(servicesList?.["MAttach"].show)
  },[servicesList])

  // useEffect for setting the mbox landing page color theme, if color not exist in apicall then it will use the default value
  useLayoutEffect(() => {
    if (color) {
      const root = document.documentElement;
      for (const key in color) {
        if (isHexColor(color[key])) {
          root.style.setProperty(key, color[key]);
        }
      }
    }
  }, [color]);

  const onSetAppId =(appId)=>{
    // debugger
    setVmAppId(appId);
  };



  return (
    <MBoxContext.Provider
      value={{
        baseUrl,
        showQrCode,
        fieldsName,
        pageTitle,
        servicesList,
        facultyUrl,
        lobbyUrl,
        streamingAdminUrl,
        qnaModeratorUrl,
        contentSessionSettingsField,
        appText,
        generalConfig,
        onSetAppId,
        isLogin,
        setIsLogin
      }}
    >
      {children}
    </MBoxContext.Provider>
  );
}

export default MBoxContext;
