import React from 'react';
import { useLocation } from 'react-router-dom';
import routes from "../../config/routes.json";
import { get } from '../../utils/SetLocalstorage';


function JmtLogin() {
  const location = useLocation();
    

  return (
    <>
    <div className='login__box__description'>App Id : {get("app_id")}</div>
    <div
      id="manage-login"
      identity_url={location?.state?.credentials?.idUrl}
      oauth_url={location?.state?.credentials?.oAuthUrl}
      client_id=""
      api_url={location?.state?.credentials?.apiUrl}
      instance="mboxSeletah"
      language="en-GB"
      event_short={location?.state?.credentials?.eventShort}
      return_url={`/auth/check/${location?.state?.credentials?.eventId}`}
      context="Event"
    ></div>
    
    </>
  );
}

export default JmtLogin