import React, {useState, useEffect} from "react";
import QRCode from "react-qr-code";
import { Modal } from "react-vm-component-library";
import CopyButton from "../copyButton/CopyButton";
import { Tooltip } from "react-tooltip";

import "./CardWithIcon.scss";
import { Link } from "react-router-dom";
import { isValidUrlCheck } from "../../utils/Validator";

const CardWithIcon = (props) => {
  const {
    icon,
    title,
    subtitle,
    description,
    link,
    id,
    children,
    showCopyButton,
    setSnackbarOpen,
    showConfig,
    onConfigClick,
    showOpenInOtherTab=true,
    containerStyle,
    showOpenCurTabBtn,
    showQrCode,
  } = props;

  const [isMounted, setIsMounted] = useState(false);
  const [qrCodeMeta, setQrCodeMeta] = useState(null);

  useEffect(() => {

    setIsMounted(true);

    return () => setIsMounted(false);

  }, [])

  const checkLinksWithParams = () => {
    var pattern = /[^{\}]+(?=})/g;
    const findMatch = link.match(pattern);

    if(findMatch){

      let newLink = "";

      for(let i=0; i<findMatch.length; i++){
        if(i === 0){
          newLink = link.replace(`{${findMatch[i]}}`, localStorage?.getItem(`${findMatch[i]}`))
        }else{
          
          newLink = newLink.replace(`{${findMatch[i]}}`, localStorage?.getItem(`${findMatch[i]}`))
        } 
      }
      return newLink;
    }
    return link;
  };

  const onHandleQrCode = () => {
    if (qrCodeMeta) {
      setQrCodeMeta(null);
      return;
    }
    setQrCodeMeta({ link });
  };
  
  return (
    <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
      {qrCodeMeta && (
        <Modal
          className="custom-css-class"
          onClose={onHandleQrCode}
          onYesClick={function noRefCheck() {}}
          rootId="modal-root"
          title={title}
          hideFooter={true}
          size="lg"
        >
          <div style={{ background: "white", padding: "1rem" }}>
            <QRCode
              size={256}
              style={{ height: "75vh", maxWidth: "100%", width: "100%" }}
              value={qrCodeMeta?.link}
              viewBox={`0 0 256 256`}
            />
          </div>
        </Modal>
      )}
      <div
        className="card cardWithIcon-container"
        style={{ ...containerStyle }}
      >
        <div className="cardWithIcon-topMiddleIcon-container">
          <i className={icon} style={{ fontSize: "25px" }}></i>
        </div>
        <div className="card-body cardWithIcon-body">
          <span className="card-title mbox-title-large">{title} </span>
          {subtitle && (
            <p className="cardWithIcon-body__subtitle">{subtitle}</p>
          )}
          {description && (
            <div className="cardWithIcon-container__desc mb-3">
              {description}
            </div>
          )}
          {children}
          <div className="cardWithIcon-container__footer">
            {showConfig && (
              <i
                className="fas fa-cog btn-gear"
                title="Enter Configuration"
                data-tooltip-content="Enter Configuration"
                data-tooltip-id="my-black-tooltip"
                onClick={onConfigClick}
              ></i>
            )}
            {link && showQrCode && (
              <i
                className="fas fa-qrcode"
                title="show qr code"
                data-tooltip-content="show qr code"
                data-tooltip-id="my-black-tooltip"
                onClick={onHandleQrCode}
              ></i>
            )}
            {link ? (
              <>
                {isValidUrlCheck(link) ? (
                  <>
                    {isValidUrlCheck(link) ? (
                      <>
                        {showCopyButton ? (
                          <CopyButton
                            link={link}
                            setSnackbarOpen={setSnackbarOpen}
                            id={id}
                          />
                        ) : null}
                      </>
                    ) : (
                      <div></div>
                    )}
                    {showOpenInOtherTab ? (
                      <>
                        <a
                          href={link}
                          target="_blank"
                          id="mbox-link-new-tab"
                          rel="noopener noreferrer"
                          className="cardWithIcon-link"
                          style={{ textDecoration: "none" }}
                        >
                          <div className="cardWithIcon-link-iconcontainer__secondary">
                            {/* <i className="fas fa-external-link-alt"></i> */}
                            <i className="fal fa-external-link"></i>
                          </div>
                        </a>
                        {isMounted && (
                          <Tooltip
                            anchorSelect="#mbox-link-new-tab"
                            content="Open in new tab"
                            place="bottom"
                          />
                        )}
                      </>
                    ) : (
                      <div></div>
                    )}
                    {showOpenCurTabBtn && (
                      <a
                        href={checkLinksWithParams()}
                        id="mbox-link-current-tab"
                        className="cardWithIcon-link"
                        style={{ textDecoration: "none" }}
                      >
                        {/* {checkLinksWithParams()} */}
                        <div className="cardWithIcon-link-iconcontainer">
                          <i className="fas fa-long-arrow-alt-right"></i>
                        </div>
                      </a>
                    )}

                    {isMounted && (
                      <Tooltip
                        anchorSelect="#mbox-link-current-tab"
                        content="Open in current tab"
                        place="bottom"
                      />
                    )}
                  </>
                ) : (
                  <Link
                    to={link}
                    className="cardWithIcon-link"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="cardWithIcon-link-iconcontainer">
                      <i className="fas fa-long-arrow-alt-right"></i>
                    </div>
                  </Link>
                )}
              </>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardWithIcon;
