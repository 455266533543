import React from "react";
import "./searchbar.scss";

function Searchbar(props) {
  const { setInput, searchInput, searchbarPlaceholder } = props;

  return (
    <div className="searchbar-container">
      <div className="searchbar-input-container">
        <div className="searchbar-input-container_icon-container">
          <i className="fas fa-search"></i>
        </div>
        <input
          className="searchbar-inputfield"
          placeholder={searchbarPlaceholder || "Enter Value"}
          value={searchInput}
          onChange={(e) => setInput(e.target.value)}
        />
        {searchInput && (
          <div className="searchbar-input-container_icon-container right">
            <i className="far fa-window-close" onClick={() => setInput("")}></i>
          </div>
        )}
      </div>
    </div>
  );
}

export default Searchbar;
