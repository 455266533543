import "./CustomColor.css";
import React, { useState, useLayoutEffect } from "react";
import { ChromePicker as SketchPicker } from "react-color";
import ImageViewer from "react-simple-image-viewer";
import ImagePreview from "./common/ImagePreview";
import useOutsideAlert from "../../../hooks/useOutsideAlert";

const CustomColor = (props) => {
  // const [show, setShow] = useState(false);
  const [color, setColor] = useState(props?.value);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const {ref, isComponentVisible, setIsComponentVisible} = useOutsideAlert(false);

  useLayoutEffect(()=>setColor(props?.value),[props?.value])

  const handleChangeComplete = (params) => {
    //setIsComponentVisible(false);
    let color = params?.hex;
    if(params?.source === "rgb" && params?.rgb?.a < 1){
      color = `rgba(${params?.rgb?.r}, ${params?.rgb?.g}, ${params?.rgb?.b} , .${params?.rgb?.a})`;
    }
    setColor(color);
    props.onChange(color);
    console.log(params)
  };
  const onToggleShow = (prev) => {
    setIsComponentVisible(!isComponentVisible);
  }; 

  const styles = {
    backgroundColor: `${color}`,
  };
  const previewImg = props?.schema?.previewImg;
  //console.log(props);
  return (
    <div className="custom-color" ref={ref}>
      {previewImg && isViewerOpen && (
        <ImageViewer
          src={[previewImg]}
          disableScroll={false}
          closeOnClickOutside={true}
          backgroundStyle={{ backgroundColor: "rgba(0,0,0,.7)", zIndex: 10 }}
          onClose={() => setIsViewerOpen(false)}
        />
      )}
      <div
        className="custom-color__input"
        onFocus={() => console.log("focus1")}
        onBlur={() => console.log("blur")}
        onClick={onToggleShow}
        style={styles}
      >
        <i className="fas fa-palette"></i>
      </div>
      {isComponentVisible && (
        <div
          style={{
            position: "absolute",
            zIndex: "99999",
            left: "50%",
            top: "-1rem",
          }}
        >
          <div className="sketch-picker__close-container">
              <i className="far fa-window-close" title="close" onClick={()=> setIsComponentVisible(false)}></i>
          </div>
          <SketchPicker
            color={color || "#eaeaea"}
            onChangeComplete={handleChangeComplete}
            className="my-sheck-color-wrapper"
          />
        </div>
      )}
      <div className="form-desc">
        <div className="form-desc__value">{color}</div>
        {previewImg && <ImagePreview onClick={() => setIsViewerOpen(true)} />}
      </div>
    </div>
  );
};

export default CustomColor;
