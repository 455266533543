import React from "react";
import Header from './header/Header';

const UserInfo = ({title="Please Contact Us to Get Access"}) => {
  return (
    <>
      {/* <Header /> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "3rem",
        }}
      >
        <h3 style={{ color: "#FF8E33" }}>{title}</h3>
      </div>
    </>
  );
};

export default UserInfo;
