export const MBoxApps = {
    MBox: {
      show: true,
      title: "OneSite Box",
      icon: "fas fa-toolbox",
      link: "m_boxes",
      description:"Please enter here to see the mapping of the OneSite Box to each session room. You will have access to all required links per session of the OneSite system.",
      sublinks: [],
    },
    MLook: {
      show: true,
      title: "OneSite eSignage",
      icon: "fas fa-telescope fa-fw",
      translation_key: "signagev3.mlook.config",
      showConfig: true,
      isUrlEncrypted:false,
      link:"https://demo.conference2web.com/e-signage-x/2023/v1",
      jsonData: {
        formData:
          "https://d3klq1qh6r64da.cloudfront.net/m-box/m-look/json/formData.json",
        schema:
          "https://d3klq1qh6r64da.cloudfront.net/m-box/m-look/json/schema.json",
        uiSchema:
          "https://d3klq1qh6r64da.cloudfront.net/m-box/m-look/json/uiSchema.json",
      },
      sublinks: [],
      description: "Please enter here to configure the eSignage design for your event.",
      openInNewTab: false,
    },
    MChair:{
      show:true,
      title:"OneSite Speech Timer",
      icon: "fas fa-loveseat fa-fw",
      translation_key:"mbox.mchair.config",
      tempId:7724,
      isUrlEncrypted:true,
      jsonData: {
        formData:
          "https://d3klq1qh6r64da.cloudfront.net/m-box/m-chair/json/formData.json",
        schema:
          "https://d3klq1qh6r64da.cloudfront.net/m-box/m-chair/json/schema.json",
        uiSchema:
          "https://d3klq1qh6r64da.cloudfront.net/m-box/m-chair/json/uiSchema.json",
      },
      showConfig:true,
      description: "Please enter here to configure the  Speech Timer design for your event."
    },
    MLectern:{
      show:true,
      title:"OneSite Lectern",
      icon: "fas fa-podium-star fa-fw",
      translation_key:"mbox.lectern.config",
      // tempId:7726,
      showConfig:true,
      isUrlEncrypted:true,
      jsonData: {
        formData:
          "https://d3klq1qh6r64da.cloudfront.net/m-box/m-lectern/json/formData.json",
        schema:
          "https://d3klq1qh6r64da.cloudfront.net/m-box/m-lectern/json/schema.json",
        uiSchema:
          "https://d3klq1qh6r64da.cloudfront.net/m-box/m-lectern/json/uiSchema.json",
      },
      description: "Please enter here to configure the digital lectern design for your event."
    },
    MChairNames:{
      tempId:7728,
    },
    QnA: {
      show: true,
      title: "Audience App",
      icon: "fas fa-comments",
      link: `https://qna.at`,
      isUrlEncrypted:true,
      showConfig: true,
      translation_key: "onsite.qna.config",
      jsonData: {
        formData:
          "https://d3klq1qh6r64da.cloudfront.net/m-box/qna-at/json/formData.json",
        schema:
          "https://d3klq1qh6r64da.cloudfront.net/m-box/qna-at/json/schema.json",
        uiSchema:
          "https://d3klq1qh6r64da.cloudfront.net/m-box/qna-at/json/uiSchema.json",
      },
      sublinks: [
        // { title: "EAN", link: "https://qna.at/?event_id=370" },
        // { title: "ECTRIMS", link: "https://qna.at/?event_id=404" },
        // { title: "DGN", link: "https://qna.at/?event_id=435" },
      ],
      openInNewTab: false,
      description: "Please enter here to configure the general layout of your Interactive Audience App."
    },
    MAttach: {
      show: false,
      title: "M Attach",
      icon: "fas fa-paperclip",
      link: `http://m-attach.virtual-meeting.net/2022/index.html`,
      showConfig: true,
      translation_key: "onsite.mattach.config",
      sublinks: [
        {
          title: "EAN",
          link: "http://m-attach.virtual-meeting.net/2022/index.html?touch=true&eventid=370",
        },
        {
          title: "ECTRIMS",
          link: "http://m-attach.virtual-meeting.net/2022/index.html?touch=true&eventid=404",
        },
        {
          title: "DGN",
          link: "http://m-attach.virtual-meeting.net/2022/index.html?touch=true&eventid=435",
        },
      ],
    },
    MAirport: {
      show: true,
      title: "OneSite Airport eSignage",
      translation_key: "signagev3.airport.config",
      icon: "fas fa-desktop",
      isUrlEncrypted:true,
      link_OLD: `https://demo.conference2web.com/vm-widget-library/2022/v1/iframe.html`,
      link:"https://demo.conference2web.com/e-signage-x/2023/v1",
      showConfig: true,
      sublinks: [
        // {
        //   title: "Demo Twitter",
        //   link: "https://demo.conference2web.com/vm-widget-library/2022/v1/iframe.html?id=mlook-airport--ticker-and-twitter&args=&viewMode=story",
        // },
      ],
      jsonData: {
        formData: "https://d3klq1qh6r64da.cloudfront.net/m-box/e-signage-airport/json/formData.json",
        schema: "https://d3klq1qh6r64da.cloudfront.net/m-box/e-signage-airport/json/schema.json",
        uiSchema: "https://d3klq1qh6r64da.cloudfront.net/m-box/e-signage-airport/json/uiSchema.json"
      },
      description: "Please enter here to configure the airport eSignage for your event."
    },
    MTV: {
      show: false,
      title: "MTV",
      icon: "fas fa-podcast",
      link: `live`,
      translation_key: "mtv.master.config",
      showConfig: true,
      sublinks: [
        { title: "EAN", link: "live?event_id=370" },
        { title: "ECTRIMS", link: "live?event_id=404" },
        { title: "DGN", link: "live?event_id=435" },
        {
          title: "DEMO mtv-x v1",
          link: "https://demo.conference2web.com/vm-widget-library/2022/v1/iframe.html?id=widgets-roomsoverview--room-as-boxes-2&args=showFilter:true&viewMode=story",
        },
        {
          title: "DEMO mtv-x v2",
          link: "https://demo.conference2web.com/vm-widget-library/2022/v1/iframe.html?id=widgets-roomsoverview--room-boxes-x&args=showFilter:true&viewMode=story",
        },
        {
          title: "DEMO Grid",
          link: "https://demo.conference2web.com/vm-widget-library/2022/v1/iframe.html?id=widgets-roomsoverview--primary&args=showFilter:true;viewMode:grid;isDemo:true&viewMode=story",
        },
      ],
    },
    VirtualMeeting1: {
      show: false,
      title: "Virtual Meeting 1.0",
      icon: "fas fa-presentation",
      sublinks: [
        { title: "EAN", link: "https://www.eanvirtualcongress.org" },
        {
          title: "DGN Virtual Meeting",
          link: "https://www.dgnvirtualmeeting.org",
        },
      ],
    },
    VirtualMeeting2: {
      show: false,
      title: "Virtual Meeting 2.0/cms",
      icon: "fas fa-users-class",
      translation_key: "virtualmeeting2",
      showConfig: true,
      sublinks: [
        { title: "ECTRIMS", link: "https://ectrims.conference2web.com" },
        { title: "DGN ONE", link: "https://dgn.org" },
        { title: "Staging", link: "https://vm2test.conference2web.com" },
        {
          title: "Admin",
          link: "https://vm2admin.conference2web.com/login/125/4yUZGN7feJLVCYj4",
        },
      ],
    },
    HdProjector:{
      //NOTE:only in session level visible(maybe another key/value to control session level)
      show: true,
      skipEventLevel:false,
      icon:"fas fa-projector fa-fw",
      title: "Projector",
      tempId:7888,
      linkQsParams:{
        has_gallery_qna:0,
        is_onsite:1,
        skip_gestures:1
      },
      showConfig:true,
      translation_key:"faculty.hd-designer.config",
      jsonData: {
        // formData:
        //   "http://localhost:3000/data/json/hd-projector/formData.json",
        formData:
        "https://d3klq1qh6r64da.cloudfront.net/m-box/hd-projector/json/formData.json",
        schema:
          "https://d3klq1qh6r64da.cloudfront.net/m-box/hd-projector/json/schema.json",
        uiSchema:
          "https://d3klq1qh6r64da.cloudfront.net/m-box/hd-projector/json/uiSchema.json",
      },
      description:"Open up this link on the device for showing the picture in picture projection (PiP). It displays the presentation slides and speaker camera."
    },
    Download:{
      show: true,
      title: "Download Statistics and exports",
      icon: "fas fa-file-download",
      sublinks: [
        {
          "title": "Voting",
          "link": "https://api-lb.virtual-meeting.net/download/events/{event_id}/voting.json?access_token={access_token}"
        },
        {
          "title": "QnA",
          "link": "https://api-lb.virtual-meeting.net/download/events/{event_id}/qna.json?access_token={access_token}"
        },
        {
          "title": "Live Stream Statistics",
          "link": "https://api-lb.virtual-meeting.net/download/events/{event_id}/mtv_playbacks_export.json?access_token={access_token}"
        },
        {
          "title": "Resources",
          "link": "https://api-lb.virtual-meeting.net/download/events/{event_id}/resources_export.json?access_token={access_token}"
        },
        {
          "title": "Resource Playbacks",
          "link": "https://api-lb.virtual-meeting.net/download/events/{event_id}/resource_playbacks_export.json?access_token={access_token}"
        },
        {
          "title": "Contentsession",
          "link": "https://api-lb.virtual-meeting.net/download/events/{event_id}/contentsession_playbacks_export.json?access_token={access_token}"
        },
        {
          "title": "Evaluation",
          "link": "https://api-lb.virtual-meeting.net/download/events/{event_id}/evaluation_export.json?access_token={access_token}"
        }
      ]
    }
}
export const FACULTY_BASE_URL ="https://demo.conference2web.com/faculty/mbox/2022/v2_27_v29/index.html";
export const STREAMING_ADMIN_BASE_URL ="https://mtalk.conference2web.com/moderator/index.html";
export const QNA_MODERATOR_BASE_URL ="https://mtalk.conference2web.com/moderator/moderator.html";
export const LOBBY_BASE_URL ="https://mtalk.conference2web.com/lobby/";

export const GeneralConfig ={
  hideOutsideChairs:true
};

export const Pages = {
  eventPage: "Events List",
  servicePage: "Services",
  mBoxPage: "OneSite Box List",
  contentsessionPage: "Session List",
  selectContentSessionPage: {
    camerasRoom: "Optional Room Cameras",
    speakerDevices: "Speaker Devices",
    speakers: "Remote Speakers",
    chairs: "Chairpersons",
    others: "Projection and eSignage",
    resourcesInformation:"Session Outline"
  },
}

export const InputFields = {
  title: "Title",
  from: "From",
  to: "To",
  ID: "ID",
  room_title: "Room Title",
  room_id: "Room ID",
}

export const AppText ={
  pages:{
    eventPage: "Events List",
    servicePage: "Services",
    mBoxPage: "OneSite Box List",
    contentsessionPage: "Session List",
    selectContentSessionPage: {
      camerasRoom: "Cameras Room",
      speakerDevices: "Speaker Devices",
      speakers: "Remote Speakers",
      chairs: "Chairpersons",
      others: "Projection and eSignage",
      resourcesInformation:"Resources Information"
    },
  },

    txtAppMBoxSpeakerCamTitle:"Lectern Camera",
    txtAppMBoxChairsCamTitle:"Chairs(ChairTable) Camera",
    txtAppMBoxAttendeesCamTitle:"Attendees Camera",
    txtAppAutoControlTitle:"Presentation Menu",
    txtAppAutoControlDesc:"Open up this link on the presentation notebook to access the presentations.",
    txtAppLecternNameTitle:"Lectern Speaker Name",
    txtAppLecternNameDesc:"Open up this link on the device which is connected to the lectern display. It displays the name of the presenting speaker.",
    txtAppSpeechTimerTitle:"Speech Timer",
    txtAppSpeechTimerDesc:"Open up this link on the timer display to view the remaining time of the current running presentation.",
    txtAppRemoteSpeakerTitle:"Remote Speaker",
    txtAppDefaultChairTitle:"Local chair",
    txtAppDefaultChairDesc:"Open this link on the notebook for the local chairperson to access the faculty view. Chairpersons can access the questions and answers and communicate with remote speakers.",
    txtAppRemoteChairTitle:"Local/Remote Chair",
    txtAppChairNamesTitle:"Outside Local Chair",
    txtAppChairNamesDesc:"Webpage/screen which shows chair name",

    txtAppProjectorTitle:"Projector",
    txtAppProjectorDesc:"Open up this link on the device for showing the picture in picture projection (PiP). It displays the presentation slides and speaker camera. ",
    txtAppMLookTitle:"OneSite eSignage",
    txtAppMLookDesc:"Open up this link on the device connected to a screen to view the agenda per session room.",
    txtAppQnAModeratorTitle:"Questions & Answers Moderator",
    txtAppQnAModeratorDesc:"Manage Q&A as a Moderator / Chair on a seperate device, like a tablet.",
    txtAppStreamingAdminTitle:"Streaming Admin",
    txtAppStreamingAdminDesc:"Info: Manage/Moderate Q&A, Voting etc..",
    txtAppStreamingLobbyTitle:"Lobby (Technician) view",
    txtAppStreamingLobbyDesc:"Info: Manage all attendees in a video call.",
    txtConfirmChanges:"Please confirm your changes by clicking button Confirm."
}