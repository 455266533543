import React from 'react'
import "./ReasignModal.css"

function ReasigneModal(props) {

    const {originRoom, roomOptions, roomChangedValue, setRoomChangedValue, updateRoom, isUpdating, id} = props;

  return (
    <div
      className="modal reasign-modal-container"
      tabIndex="-1"
      role="dialog"
      id={`reasigneModal_${id}`}
    >
      <div className="modal-dialog modal-dialog-centered" role="document" >
        <div className="modal-content reasign-modal__content">
          <div className="modal-header">
            <h5 className="modal-title mbox-headline-medium">
              Room Reassign
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" style={{ color: "white" }}>
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body reasign-modal__content__body">
    
              <span className='mr-3 mbox-title-medium' style={{textAlign:"center", width:"50%"}}> {originRoom}</span>
            
            <div>
              <i className="fas fa-exchange-alt mr-3"></i>
            </div>
            <div className="dropdown" style={{width:"50%"}}>
              <button
                className="btn btn-secondary dropdown-toggle reasign-modal__dropdown-button"
                type="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {!roomChangedValue.id
                  ? "Select Room"
                  : `${roomChangedValue.title} (${roomChangedValue.id})`}
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                {roomOptions?.map((room) => (
                  <button
                    key={room?.id}
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      setRoomChangedValue({ id: room.id, title: room.title })
                    }
                  >
                    ({room.id}) {room.title}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="modal-footer" style={{ borderTop: "none" }}>
          <button
              type="button"
              className="reasing-modal__close-button"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="mBoxListCard-link mbox-captions"
              style={{ borderRadius: "8px" }}
              onClick={updateRoom}
              disabled={!roomChangedValue.id || isUpdating}
            >
              {isUpdating ? (
                <>
                  {" "}
                  <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </>
              ) : (
                "Save Changes"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReasigneModal