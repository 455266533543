import React from "react";
import { isNullOrWhitespace } from "../../utils/Validator";

const Page = ({ children, name, ...rest }) => {
    const cssClasses =["mbox-page"];
    if(!isNullOrWhitespace(name))
        cssClasses.push(name)
  return (
    <div className={cssClasses.join(" ")}>
      <div className="container" style={{padding: "1rem"}}>{children}</div>
    </div>
  );
};

export default Page;
