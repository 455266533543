import React, { useRef } from "react";
import { makeHttpCall } from "../../utils/HttpHelpers";
import "./ImageUploadButton.css";

const ImageUploadButton = (props) => {
  const { onImageChange } = props;

  const fileInput = useRef();
  const selectFile = () => {
    fileInput.current.click();
  };

  const handleClick = async () => {
    const postData = new FormData();
    postData.append("event_id", 125);
    postData.append("path", "web-apps");
    postData.append("avatar", fileInput.current.files[0]);

    let url = "https://api.virtual-meeting.net/v1/filesuploads.json";

    const apiResp = await makeHttpCall({
      url: url,
      method: "POST",
      bodyJson: postData,
      isVm: true,
    });

    if (apiResp?.ok) {
      onImageChange(apiResp?.data?.file_path);
    }
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInput}
        style={{ display: "none" }}
        onChange={handleClick}
      />
      <button
        className="image-upload--button"
        type="button"
        onClick={selectFile}
      >
        Upload Image
      </button>
    </div>
  );
};

export default ImageUploadButton;
