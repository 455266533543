import React, { useEffect, useState } from "react";
import { makeHttpCall } from "../../utils/HttpHelpers";
import { useParams } from "react-router-dom";

function AuthCheck() {
  const [isError, setIsError] = useState({ error: false, message: "" });

  const pathParams = useParams();

  const checkLoginData = async () => {
    const url = `https://api-lb.virtual-meeting.net/v0/logins/jwt.json`;
    const postData = new FormData();
    postData.append("event_id", pathParams.id);
    postData.append(
      "jwt_token",
      JSON.parse(localStorage.getItem("mboxSeletah.user")).accessToken
    );
    postData.append("include_user", 1);
    postData.append("include_grants", 1);
    postData.append("include_manage_context_roles", 1);
    postData.append("include_event_id", 0);

    const loginData = await makeHttpCall({
      url: url,
      method: "POST",
      bodyJson: postData,
      isVm: true,
    });

    if (loginData?.ok) {
      const roles = loginData?.data?.grants;

      let isAdmin = false;

      for (let i = 0; i < roles.length; i++) {
        if (
          roles[i]?.role?.title === "m box admin" ||
          roles[i]?.role?.title === "vm mbox admin"
        ) {
          isAdmin = true;
        }
      }

      if (isAdmin) {
        localStorage.setItem("role", "vm mbox admin");
        localStorage.setItem("access_token", loginData.data.access_token);
        localStorage.setItem("expires_at", loginData.data.expires_at);
        window.location.reload();
      }
      if (!isAdmin) {
        setIsError({
          error: true,
          message: "You are not the admin for this service",
        });
      }
    }
  };

  useEffect(() => {
    checkLoginData();
  }, []);

  if (isError.error) {
    return (
      <div
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        You are not the admin for this service
      </div>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default AuthCheck;
