import React, { useState, useRef, useEffect } from "react";
import {
  Airport,
  MLook,
  RoomsOverview,
  Live,
} from "react-vm-component-library";
import Alert from "../../common/Alert";
import { Sticky } from "./Sticky";
import { setCssVariables, removeCssVariables } from './../../../utils/Tools';

const AppPreview = ({ data, appKey }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const appContainerRef = useRef(null);
  const appCssVarsRef = useRef(null);

  useEffect(()=>{
    document.getElementsByTagName("body")[0].style.overflowY = isFullscreen ?"hidden" :"auto";
  },[isFullscreen]);

  useEffect(()=>{
    if(data?.colors){
      appCssVarsRef.current = data?.colors;
      setCssVariables(data?.colors)
    }
    return ()=>{
      if(appCssVarsRef.current)
        removeCssVariables(data?.colors);
    }
  },[appKey, data?.colors])

  const onHandleManageSize = () => {
    setIsFullscreen((prev) => !prev);
  };

  const renderContent = () => {
    switch (appKey) {
      case "signagev3.mlook.config":
        //console.log(data)
        return <MLook {...data?.component} room_id={4192} isDemo={true} />;
      case "signagev3.airport.config":
        return <Airport {...data?.component} event_id={125} isDemo={true} />;
      case "onsite.qna.config":
        return (
          <div className="mbox-jme-qna-app">
            <RoomsOverview
              liveIconType="type3"
              {...data?.roomsConfig}
              viewMode="list"
              event_id={125}
              isDemo={true}
            />
          </div>
        );
      default:
        return <Alert type="warning">No apps found</Alert>;
    }
  };

  const cssClasses = ["me-app-preview"];
  if (isFullscreen) cssClasses.push("me-app-preview--has-fullscreen");
  //console.log(data?.component);
  return (
    <div className={cssClasses.join(" ")} ref={appContainerRef}>
      <Sticky parentElRef={appContainerRef} isFullscreen={isFullscreen}>
        <div className="me-app-preview__header">
          <div className="info-text">
              <Live liveIconType="type4" txtLive="Live settings App Preview" />
              <small class="text-muted">NOTE: not every value change will have impact on <u>preview</u></small>
          </div>
          <div
            className="manage-size"
            data-tooltip-content={
              isFullscreen ? "Exit Fullscreen" : "Fullscreen"
            }
            data-tooltip-id="my-tooltip"
          >
            {/* <i class="far fa-times-circle"></i> */}
            <i
              className={isFullscreen ? "far fa-times-circle" : "fas fa-expand"}
              onClick={onHandleManageSize}
            ></i>
          </div>
        </div>
        <div className="me-app-preview__container">{renderContent()}</div>
      </Sticky>
    </div>
  );
};

export default AppPreview;
