import React, { useLayoutEffect } from "react";
import useQuery from "../../hooks/useQuery";

const Live = () => {
  let query = useQuery();
  useLayoutEffect(() => {
    window.mtvApp.setup({
      id: "mtv-container",
      path: "https://d3klq1qh6r64da.cloudfront.net/api_uploads/mtv-master/jme/",
      base_api: "api-lb.virtual-meeting.net",
      event_id: query.get("event_id"),
    });
    return () => {
      window.mtvApp.destroy();
    };
  }, [query]);

  return (
    <div className="me-live-container">
      <div id="mtv-container"></div>
    </div>
  );
};

export default Live;
