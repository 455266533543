import "./Sticky.scss";
import React, { useEffect, useRef } from "react";

const Sticky = ({ className, parentElRef, isFullscreen, children, ...rest }) => {
  const containerRef = useRef(null);
  useEffect(() => {
    var navbar = containerRef?.current;
    var sticky = navbar.getBoundingClientRect().top + window.scrollY;

    function onScrollHandler() {
      if (!navbar) return;
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky");
        if(parentElRef?.current) navbar.style.width=`${parentElRef?.current?.offsetWidth}px`;
      } else {
        navbar.classList.remove("sticky");
        navbar.style.width=`unset`;
      }
      if(isFullscreen){
        navbar.classList.remove("sticky");
        navbar.style.width=`unset`;
      }

    }
    document.addEventListener("scroll", onScrollHandler);
    onScrollHandler();
    return () => document.removeEventListener("scroll", onScrollHandler);
  }, [parentElRef, isFullscreen]);
  const cssClassNames = ["me-sticky-container"];
  if (className) cssClassNames.push(className);
  return (
    <div ref={containerRef} className={cssClassNames.join(" ")}>
      {children}
    </div>
  );
};

export {Sticky};
