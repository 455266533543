import React, {useEffect} from "react";
import "./home.scss";
import Page from './../../components/common/Page';


const Home = () => {


  useEffect(() => {
    if(!!localStorage.getItem("app_id")){
      // window.location.href(`/apps/${localStorage.getItem("app_id")}`)
      window.location.href = `/apps/${localStorage.getItem("app_id")}`
    }
  },[])

  return (
    <Page name="home-page">
      <div className="homePage-container" style={{fontSize:"20px"}}>
        Please use either /apps/:id or /oca url to continue login. 
    </div>
    </Page>
    
  );
};

export default Home;
