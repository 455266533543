import "./CustomString.css";
import React, { useState, useEffect } from "react";
import ImagePreview from "./common/ImagePreview";
import ImageViewer from "react-simple-image-viewer";
import { isUndefined } from "lodash";
import { isHexColor, isURLImage, isValidURL } from "../../../utils/Validator";
import CustomColor from "./CustomColor";
import ImageUploadButton from "../../imageUploadButton/ImageUploadButton";

const CustomString = (props) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [viewImage, setViewImage] = useState(null);
  const [isImgError, setIsImgError] = useState(false);
  const { value } = props;

  const previewImg = props?.schema?.previewImg;

  useEffect(() => {
    const isImage =
      (isValidURL(value) && isURLImage(value)) || props?.schema?.isImg;
    if (isImage) {
      isURLImage(value) ? setIsImgError(false) : setIsImgError(true);
    }
  }, [value, props?.schema?.isImg]);

  const handleValueChange = (e) => {
    props.onChange(e.target.value);
  };

  if (isHexColor(value)) {
    return <CustomColor {...props} />;
  }
  const isImage =
    (isValidURL(value) && isURLImage(value)) || props?.schema?.isImg;
  // if(isImage)
  //   console.log(`${props?.schema?.title}: ${props?.value}: ${isImgError}`);
  return (
    <div className="custom-string-container">
      <div className="custom-string-container-input">
        <input
          className="form-control custom-string-container__input"
          label="txtLive456"
          type="text"
          value={!isUndefined(value) ? value : ""}
          onChange={(e) => handleValueChange(e)}
          readOnly={props?.readonly ? true : false}
        />
        {previewImg && (
          <ImagePreview text="" onClick={() => setIsViewerOpen(true)} />
        )}
        {previewImg && isViewerOpen && (
          <ImageViewer
            src={[previewImg]}
            disableScroll={false}
            closeOnClickOutside={true}
            backgroundStyle={{ backgroundColor: "rgba(0,0,0,.7)", zIndex: 10 }}
            onClose={() => setIsViewerOpen(false)}
          />
        )}
      </div>
      {isImage && (
        <div className="custom-string-container-image-upload-preview">
          <ImageUploadButton onImageChange={props.onChange} />
          {!isImgError ? (
            <img
              className="custom-string-container-previewImage"
              src={value}
              alt=""
              onError={() => setIsImgError(true)}
              onClick={() => setViewImage({ src: value })}
            />
          ) : (
            <p className="custom-string-container-not-valid-url-text">
              *Not a valid url for image or preview image cannot display, please
              check the url.
            </p>
          )}
          {viewImage && (
            <ImageViewer
              src={[viewImage?.src]}
              disableScroll={false}
              closeOnClickOutside={true}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,.7)",
                zIndex: 10,
              }}
              onClose={() => setViewImage(null)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CustomString;
