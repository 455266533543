import React from "react";
import moment from "moment-timezone";

const SessionHeader = ({ session }) => {
  const dateFormatShowTime = new Intl.DateTimeFormat("de-DE", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  const startTime =moment(session?.starts_at_local);
  const isToday =startTime.isSame(new Date(), "day");
  return (
    <div className="session-header">
      {/* <span>{dateFormatShowTime.format(new Date(session?.starts_at_local))}</span> */}
        {!isToday && <span className="session-header_date"> {startTime.format("DD-MM-YYYY")}</span>}
      <span className="session-header_date">
        {startTime.format("HH:mm")}-
        {moment(session?.ends_at_local).format("HH:mm")}
      </span>
      <span className="session-header_title">{session?.title}</span>
    </div>
  );
};

export default SessionHeader;
