import _ from "lodash";

const AUTH_DATA = { Authorization: `Basic ${btoa("m-events:kims")}` };

export const makeHttpCall = async (requestConfig) => {
  const controller = new AbortController();
  // 5 second timeout:
  const timeoutId = setTimeout(() => controller.abort(), 30000);
  const {
    url,
    method = "GET",
    headers = {},
    bodyJson,
    isResponseText = false,
    isVm = false,
  } = requestConfig;
  const apiRequestConfig = {
    method,
    headers,
    body: bodyJson,
    signal: controller.signal,
  };
  if (bodyJson && bodyJson instanceof URLSearchParams) {
    apiRequestConfig.body = bodyJson.toString();
  }
  if (isVm && !(bodyJson instanceof FormData)) {
    apiRequestConfig.headers = _.merge(requestConfig.headers, AUTH_DATA, {
      "Content-Type":
        method === "POST"
          ? "application/x-www-form-urlencoded"
          : "application/json",
      Accept: "application/json, text/plain, text/javascript */*",
    });
  }

  if(isVm && bodyJson instanceof FormData) {
    apiRequestConfig.headers = _.merge(requestConfig.headers, AUTH_DATA, {
      // "Content-Type":`multipart/form-data; boundary=some-random-charactors` ,
      Accept: "application/json, text/plain, text/javascript */*",
    });
  }

  try {
    //console.log(`url=${url}`)
    const response = await fetch(url, apiRequestConfig);
    if (!response.ok) {
      const errorResp = await response.json();
      if(errorResp)return { ok: false, ...errorResp }
      throw new Error("Request failed!");
    }

    const data = isResponseText ? await response.text() : await response.json();

    if (Array.isArray(data)) return { ok: true, data: [...data] };
    return { ok: true, data: { ...data } };
  } catch (err) {
    console.log(err);
    return { ok: false, ...err };
  }
};
