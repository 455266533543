import React, { useState } from "react";
import Page from "../../components/common/Page";
import "./onSite.css";
import { getBaseUrl } from './../../utils/QueryString';

const OnSite = () => {
  const [inputs, setInputs] = useState({});

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var headers = new Headers();

    headers.append("Authorization", "Basic " + btoa("m-events:kims"));

    fetch(
      `https://${getBaseUrl()}/onsite/m_boxes/${inputs.m_box_id}.json?event_id=${inputs.event_id}&include_settings=0`,
      { headers: headers }
    )
      .then((res) => res.json())
      .then((result) => {
      

        fetch(
          `https://${getBaseUrl()}/v1/contentsessions.json?room_id=${result.rooms[0].id}&output=mtv`,
          { headers: headers }
        )
          .then((res) => res.json())
          .then((result) => {
           

            window.location.href = `https://mtalk.conference2web.com/faculty/?sid=${result[0].id}&autojoinAs=PC ${inputs.pc_id}&autoConnect=1`;
          });
      });
};

  return (
    <Page name="onsite-page">
      <div className="onSite-title-container">
        <p>Onsite</p>
      </div>
      <form  onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Enter M Box ID:</label>
          <input
            type="text"
            className="form-control"
            id="m_box_id_input"
            value={inputs?.m_box_id}
            placeholder="M Box ID"
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Enter PC ID:</label>
          <input
            type="text"
            className="form-control"
            id="pc_id_input"
            value={inputs?.pc_id}
            placeholder="PC ID"
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Enter Event ID:</label>
          <input
            type="text"
            className="form-control"
            id="event_id_input"
            value={inputs?.event_id}
            placeholder="Event ID"
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="btn onSite-button">
          Sign in
        </button>
      </form>
    </Page>
  );
};

export default OnSite;
