import { useState, useEffect } from "react";

const useApiHook = (url) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true)
    var headers = new Headers();
    headers.append("Authorization", "Basic " + btoa("m-events:kims"));
    fetch(url, { headers: headers })
      .then((res) => res.json())
      .then((result) => {
        setData(result);
        setIsLoading(false);
      });
  }, []);

  return {data,isLoading};
};

export default useApiHook;
