import React from "react";
import "./Alert.scss";
import PropTypes from "prop-types";

const TYPES = {
  SUCCESS: "success",
  WARNING: "warning",
  INFO: "info",
  DANGER: "danger",
};

const SIZES =["m", "l", "xl"];
const Alert = ({ type = "success", size, hideClose, onCloseClick, children }) => {
  const cssClasses = ["mbox-alert"];
  if (typeof type === "string") cssClasses.push(`type-${type}`);
  if(SIZES.includes(size)) cssClasses.push(`size-${size}`)

  let iconJsx = null;
  if (type === TYPES.SUCCESS) iconJsx = <i className="fas fa-check-circle"></i>;
  else if (type === TYPES.WARNING)
    iconJsx = <i className="fas fa-exclamation-triangle"></i>;
  else if (type === TYPES.INFO)
    iconJsx = <i className="fas fa-info-circle"></i>;
  else if (type === TYPES.DANGER)
    iconJsx = <i className="fas fa-exclamation-triangle"></i>;

  return (
    <div className={cssClasses.join(" ")}>
      {iconJsx}
      {children}
      {!hideClose && (
        <i
          className="fas fa-times-circle btn-close-floating"
          title="close"
          onClick={onCloseClick}
        ></i>
      )}
    </div>
  );
};

Alert.defaultProps = {
  type: "success",
  hideClose: true,
};

Alert.propTypes = {
  type: PropTypes.oneOf(["success", "warning", "info", "danger"]),
  size: PropTypes.oneOf([...SIZES]),
};

export default Alert;
